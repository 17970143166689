var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "layout-mask",
      attrs: { "ma-0": "" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          ;("")
        }
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: {
            "flex-basis": "30%",
            "background-color": "transparent",
            display: "flex",
            "justify-content": "center",
            "align-items": "center"
          }
        },
        [
          _c(
            "v-progress-circular",
            _vm._g(
              {
                staticClass: "ml-1",
                attrs: { size: "80", indeterminate: "", color: "blue" }
              },
              _vm.on
            ),
            [
              _vm.progress.steps_success_num
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.progress.steps_success_num) +
                        "/" +
                        _vm._s(_vm.progress.steps_total_num)
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticStyle: { "flex-basis": "70%" },
          attrs: { "d-flex": "", "align-center": "" }
        },
        [
          _c(
            "div",
            { staticStyle: { color: "#0f0f0f" } },
            [
              _vm._l(_vm.progress.steps, function(item, index) {
                return _c("p", { key: index, staticClass: "my-2 " }, [
                  _vm._v(
                    "Step " +
                      _vm._s(index + 1) +
                      ". " +
                      _vm._s(item.description) +
                      "."
                  )
                ])
              }),
              !_vm.progress.steps
                ? _c("span", [
                    _vm._v(
                      "Deploying the application. It may take 3-5 minutes."
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }