var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "applications ", attrs: { "grid-list-lg": "" } },
    [
      _c(
        "v-subheader",
        { staticClass: "panel-title" },
        [
          _c("h3", [_vm._v("My Applications")]),
          _c("v-spacer"),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  label: "Show Cancelled Applications(" + _vm.cancelledNum + ")"
                },
                model: {
                  value: _vm.showCancel,
                  callback: function($$v) {
                    _vm.showCancel = $$v
                  },
                  expression: "showCancel"
                }
              }),
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "text-transform": "none",
                    "margin-left": "40px"
                  },
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/ordering")
                    }
                  }
                },
                [_vm._v("Order a New Product\n            ")]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.loading && _vm.items.length !== 0
        ? _c(
            "div",
            {
              staticClass: "mt-5",
              staticStyle: { "margin-right": "-16px", "margin-left": "-16px" }
            },
            [
              _c(
                "v-layout",
                { staticClass: "package-wrapper", attrs: { wrap: "" } },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "v-flex",
                    {
                      key: item.uuid,
                      attrs: {
                        classs: "card-wrapper",
                        md6: "",
                        lg6: "",
                        sm12: ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _vm.getPackageStatus(item) === "process"
                            ? _c("ApplicationProcess", {
                                attrs: { package_uuid: item.uuid },
                                on: {
                                  success: function($event) {
                                    return _vm.refreshSinglePackage(item.uuid)
                                  }
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-card-title",
                            {
                              staticClass: "px-3 py-1",
                              style: {
                                background:
                                  _vm.colorMap[
                                    _vm.getPackageStatus(item) + "-bg"
                                  ]
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticStyle: { opacity: "1" },
                                  attrs: { row: "", "align-center": "" }
                                },
                                [
                                  _c("v-flex", { attrs: { "xs-3": "" } }, [
                                    _c("img", {
                                      staticStyle: { height: "60px" },
                                      attrs: {
                                        src: _vm.getProductNotext(
                                          item.product_name
                                        )
                                      }
                                    })
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { xs6: "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "16px",
                                            display: "inline-block"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.name) +
                                              "(v" +
                                              _vm._s(item.version) +
                                              ")"
                                          )
                                        ]
                                      ),
                                      _vm.getPackageStatus(item) === "Active"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "i",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "fa fa-pencil-square-o ma-2 edit-btn",
                                                              style: {
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .primary
                                                              },
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return _vm.$refs.rename.open(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Rename")])]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs3: "",
                                        "d-flex": "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _vm.getPackageStatus(item) !== "process"
                                        ? _c(
                                            "div",
                                            {
                                              style: {
                                                color:
                                                  _vm.colorMap[
                                                    _vm.getPackageStatus(item)
                                                  ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.getPackageStatus(item)
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        [
                                          _vm.getPackageStatus(item) ===
                                          "Active"
                                            ? _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    transition:
                                                      "slide-x-transition"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    title:
                                                                      "Click to manage your application."
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "more_vert"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.getCategoryMenus(
                                                        item
                                                      ),
                                                      function(n, i) {
                                                        return _c(
                                                          "v-list-tile",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              to: n.path
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(n.text)
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.getPackageStatus(item) ===
                                          "Pending"
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.$refs.cancelconfirm.open(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "grey"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    delete\n                                                "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Cancel your package"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-layout",
                                { attrs: { "px-3": "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "body-2 title font-weight-black",
                                      attrs: {
                                        "pt-2": "",
                                        xs4: "",
                                        md4: "",
                                        sm4: ""
                                      }
                                    },
                                    [_vm._v("Created")]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "content body-1",
                                      attrs: {
                                        "pt-2": "",
                                        xs8: "",
                                        md8: "",
                                        sm8: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.formatDate(item.created))
                                      )
                                    ]
                                  ),
                                  _c("v-flex", {
                                    staticClass:
                                      "body-2 title font-weight-black",
                                    attrs: {
                                      "pt-2": "",
                                      xs4: "",
                                      md4: "",
                                      sm4: ""
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        item.remain_days >= 0
                                          ? "Remain"
                                          : ["Cancelled", "Pending"].indexOf(
                                              _vm.getPackageStatus(item)
                                            ) !== -1
                                          ? "Remain"
                                          : "Over Due"
                                      )
                                    }
                                  }),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "content body-1",
                                      attrs: {
                                        "pt-2": "",
                                        xs8: "",
                                        md8: "",
                                        sm8: ""
                                      }
                                    },
                                    [
                                      ["Cancelled", "Pending"].indexOf(
                                        _vm.getPackageStatus(item)
                                      ) === -1
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  color:
                                                    item.remain_days >= 0
                                                      ? "black"
                                                      : "red"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    Math.abs(item.remain_days)
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                    day(s)\n                                "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                                            --\n                                        "
                                            )
                                          ])
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "title font-weight-black body-2 ",
                                      attrs: {
                                        "pt-2": "",
                                        xs4: "",
                                        md4: "",
                                        sm4: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Billing Cycle\n                            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "content body-1",
                                      attrs: {
                                        "pt-2": "",
                                        xs8: "",
                                        md8: "",
                                        sm8: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            item.price.duration_description
                                          ) +
                                          "\n                                "
                                      ),
                                      _vm.getPackageStatus(item) === "Active"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "i",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "fa fa-pencil-square-o ml-2 edit-btn",
                                                              style: {
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .primary
                                                              },
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  $event.preventDefault()
                                                                  return _vm.$refs.billingcycle.open(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Change Billing Cycle")
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "body-2 title font-weight-bold",
                                      attrs: {
                                        "pt-2": "",
                                        xs4: "",
                                        md4: "",
                                        sm4: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Configuration\n                            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "content body-1",
                                      attrs: {
                                        "pt-2": "",
                                        xs8: "",
                                        md8: "",
                                        sm8: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                (" +
                                          _vm._s(item.plan_name) +
                                          ")" +
                                          _vm._s(item.cpu) +
                                          " " +
                                          _vm._s(
                                            item.cpu > 1 ? "cores" : "core"
                                          ) +
                                          " /\n                                " +
                                          _vm._s(parseInt(item.memory)) +
                                          "GB RAM / " +
                                          _vm._s(item.backup_storage) +
                                          " GB SSD\n                            "
                                      )
                                    ]
                                  ),
                                  _vm.getPackageStatus(item) === "Cancelled"
                                    ? [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "title font-weight-black body-2 ",
                                            attrs: {
                                              "pt-2": "",
                                              xs4: "",
                                              md4: "",
                                              sm4: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Backup Date\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "content body-1",
                                            attrs: {
                                              "pt-2": "",
                                              xs8: "",
                                              md8: "",
                                              sm8: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  item.backup_info
                                                    ? _vm.formatDate(
                                                        item.backup_info
                                                          .backup_date
                                                      )
                                                    : "--"
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "title font-weight-black body-2 ",
                                            attrs: {
                                              "pt-2": "",
                                              xs4: "",
                                              md4: "",
                                              sm4: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Backup Size\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "content body-1",
                                            attrs: {
                                              "pt-2": "",
                                              xs8: "",
                                              md8: "",
                                              sm8: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  item.backup_info
                                                    ? item.backup_info
                                                        .backup_size + "GB"
                                                    : "--"
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "title font-weight-black body-2 ",
                                            attrs: {
                                              "pt-2": "",
                                              xs4: "",
                                              md4: "",
                                              sm4: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Cancel Reason\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "content body-1",
                                            attrs: {
                                              "pt-2": "",
                                              xs8: "",
                                              md8: "",
                                              sm8: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  item._package_delete_reason ||
                                                    "--"
                                                ) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            {
                              staticClass: " py-4",
                              staticStyle: { "min-height": "44px" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "px-3": "",
                                    "justify-space-around": ""
                                  }
                                },
                                [
                                  _c("v-flex", { attrs: { shrink: "" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-none",
                                            attrs: {
                                              title:
                                                "Click to manage your application.",
                                              outline: "",
                                              color: "primary",
                                              disabled:
                                                _vm.getPackageStatus(item) !==
                                                "Active",
                                              to:
                                                _vm.getPackageStatus(item) ===
                                                "Active"
                                                  ? "/applications/" + item.uuid
                                                  : ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Manage\n                                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "" } },
                                    [
                                      _c("AppBtn", {
                                        attrs: { app: item },
                                        on: {
                                          success: _vm.freeTrialHanlder,
                                          deploy: _vm.deploySuccess
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? [
            _c(
              "div",
              { staticClass: "my-3", staticStyle: { height: "60vh" } },
              [_c("Spin")],
              1
            )
          ]
        : _vm._e(),
      !_vm.loading && _vm.items.length === 0
        ? [
            _c(
              "div",
              { staticClass: "my-3", staticStyle: { height: "60vh" } },
              [_c("Empty")],
              1
            )
          ]
        : _vm._e(),
      _c("ChangeBillingCycle", {
        ref: "billingcycle",
        on: { confirm: _vm.changeBillingCycle }
      }),
      _c("EditText", { ref: "rename", on: { confirm: _vm.save } }),
      _c("Confirm", {
        ref: "cancelconfirm",
        on: { confirm: _vm.cancelPackage },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    "\n                Cancel your application\n            "
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    "\n                You are about to cancel the application. This operation can not be undone. Do you want to continue?\n            "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }