<template>
    <v-container class="applications " grid-list-lg>
        <v-subheader class="panel-title">
            <h3>My Applications</h3>
            <v-spacer></v-spacer>
            <div style="display: flex;align-items: center;">
                <v-switch
                        color="primary"
                        v-model="showCancel"
                        :label="`Show Cancelled Applications(${cancelledNum})`"
                ></v-switch>
                <v-btn color="primary" style="text-transform: none;margin-left: 40px;"
                       @click="$router.push('/ordering')">Order a New Product
                </v-btn>
            </div>
        </v-subheader>
        <div class="mt-5" v-if=" !loading && items.length!==0 " style="margin-right: -16px;margin-left: -16px">
            <v-layout class="package-wrapper" wrap>
                <v-flex classs="card-wrapper" v-for="(item) in items" :key="item.uuid"  md6 lg6 sm12>
                    <v-card >
                        <ApplicationProcess v-if="getPackageStatus(item)==='process'"
                                            :package_uuid="item.uuid"
                                            @success="refreshSinglePackage(item.uuid)"></ApplicationProcess>
                        <v-card-title class="px-3 py-1" :style="{background:colorMap[getPackageStatus(item)+'-bg']}">
                            <v-layout row align-center style="opacity: 1;">
                                <v-flex xs-3>
                                    <img style="height: 60px;" :src="getProductNotext(item.product_name)">
                                </v-flex>
                                <v-flex xs6 style="text-align: center;">
                                    <span style="font-size: 16px;display: inline-block;">{{item.name}}(v{{item.version}})</span>
                                    <v-tooltip top v-if="getPackageStatus(item)==='Active'">
                                        <template v-slot:activator="{ on }">
                                            <i class="fa fa-pencil-square-o ma-2 edit-btn" v-on="on" aria-hidden="true"
                                               :style="{color: $vuetify.theme.primary}"
                                               @click.prevent="$refs.rename.open(item)"></i>
                                        </template>
                                        <span>Rename</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex xs3 d-flex align-center>
                                    <div v-if="getPackageStatus(item) !== 'process'"
                                         :style="{color: colorMap[getPackageStatus(item)]}">
                                        {{getPackageStatus(item)}}
                                    </div>
                                    <div>
                                        <v-menu v-if="getPackageStatus(item)==='Active'"
                                                transition="slide-x-transition">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on" @click.prevent
                                                       :title="`Click to manage your application.`">
                                                    <v-icon color="primary">more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-tile
                                                        v-for="(n, i) in getCategoryMenus(item)"
                                                        :to="n.path"
                                                        :key="i">
                                                    <v-list-tile-title>{{ n.text }}</v-list-tile-title>
                                                </v-list-tile>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip top v-if="getPackageStatus(item) === 'Pending'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon
                                                       @click.stop.prevent="$refs.cancelconfirm.open(item)">
                                                    <v-icon color="grey">
                                                        delete
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Cancel your package</span>
                                        </v-tooltip>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <v-card-text>
                            <v-layout px-3 wrap>
                                <v-flex class="body-2 title font-weight-black" pt-2 xs4 md4 sm4>Created</v-flex>
                                <v-flex pt-2 class="content body-1" xs8 md8 sm8>{{formatDate(item.created)}}</v-flex>
                                <v-flex class="body-2 title font-weight-black" pt-2 xs4 md4 sm4
                                        v-text="item.remain_days>=0?'Remain':['Cancelled','Pending'].indexOf(getPackageStatus(item))!==-1?'Remain':'Over Due'"></v-flex>
                                <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                    <div v-if="['Cancelled','Pending'].indexOf(getPackageStatus(item))===-1">
                                        <span :style="{color: item.remain_days>=0?'black':'red'}">{{Math.abs(item.remain_days)}}</span>
                                        day(s)
                                    </div>
                                    <span
                                            v-else>
                                                --
                                            </span>
                                </v-flex>
                                <v-flex class="title font-weight-black body-2 " pt-2 xs4 md4 sm4>
                                    Billing Cycle
                                </v-flex>
                                <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                    {{item.price.duration_description}}
                                    <v-tooltip top v-if="getPackageStatus(item)==='Active'">
                                        <template v-slot:activator="{ on }">
                                            <i class="fa fa-pencil-square-o ml-2 edit-btn" v-on="on"
                                               @click.stop.prevent="$refs.billingcycle.open(item)" aria-hidden="true"
                                               :style="{color: $vuetify.theme.primary}"></i>
                                        </template>
                                        <span>Change Billing Cycle</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex class="body-2 title font-weight-bold" pt-2 xs4 md4 sm4>
                                    Configuration
                                </v-flex>
                                <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                    ({{item.plan_name}}){{item.cpu}} {{item.cpu>1?'cores':'core'}} /
                                    {{parseInt(item.memory)}}GB RAM / {{item.backup_storage}} GB SSD
                                </v-flex>
                                <template v-if="getPackageStatus(item)==='Cancelled'">
                                    <v-flex pt-2 class="title font-weight-black body-2 " xs4 md4 sm4>
                                        Backup Date
                                    </v-flex>
                                    <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                        {{item.backup_info?formatDate(item.backup_info.backup_date):'--'}}
                                    </v-flex>
                                    <v-flex pt-2 class="title font-weight-black body-2 " xs4 md4 sm4>
                                        Backup Size
                                    </v-flex>
                                    <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                        {{item.backup_info?item.backup_info.backup_size+'GB':'--'}}
                                    </v-flex>
                                    <v-flex pt-2 class="title font-weight-black body-2 " xs4 md4 sm4>Cancel Reason
                                    </v-flex>
                                    <v-flex pt-2 class="content body-1" xs8 md8 sm8>
                                        {{item._package_delete_reason||'--'}}
                                    </v-flex>
                                </template>
                            </v-layout>
                        </v-card-text>
                        <v-card-actions class=" py-4" style="min-height: 44px">
                            <v-layout px-3 justify-space-around>
                                <v-flex shrink>
                                    <div>
                                        <v-btn :title="`Click to manage your application.`" class="text-none" outline
                                               color="primary" :disabled="getPackageStatus(item)!=='Active'"
                                               :to="getPackageStatus(item)==='Active'?`/applications/${item.uuid}`:''">
                                            Manage
                                        </v-btn>
                                    </div>
                                </v-flex>
                                <v-flex shrink>
                                    <AppBtn :app="item" @success="freeTrialHanlder" @deploy="deploySuccess"></AppBtn>
                                </v-flex>
                            </v-layout>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </div>
        <template v-if="loading">
            <div class="my-3" style="height: 60vh">
                <Spin></Spin>
            </div>
        </template>
        <template v-if="!loading && items.length===0">
            <div class="my-3" style="height: 60vh">
                <Empty></Empty>
            </div>
        </template>
        <ChangeBillingCycle @confirm="changeBillingCycle" ref="billingcycle">
        </ChangeBillingCycle>
        <EditText @confirm="save" ref="rename"></EditText>
        <Confirm ref="cancelconfirm" @confirm="cancelPackage">
            <template v-slot:title>
                <span>
                    Cancel your application
                </span>
            </template>
            <template v-slot:content>
                <p>
                    You are about to cancel the application. This operation can not be undone. Do you want to continue?
                </p>
            </template>
        </Confirm>
    </v-container>
</template>

<script>
    import Confirm from '@/components/Confirm.vue'
    import {formatDate} from '@/module/utils/date'
    import {colorMap} from '@/module/constant.js'
    import Spin from '@/components/Spin.vue'
    import Empty from '@/components/Empty.vue'
    import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'
    import ApplicationProcess from './_source/ApplicationProcess.vue'
    import EditText from './_source/EditText.vue'
    import ChangeBillingCycle from './_source/ChangeBillingCycle.vue'
    import AppBtn from './_source/AppBtn.vue'


    export default {
        name: "Applications",
        components: {Spin, Empty, ApplicationProcess, EditText, ChangeBillingCycle, AppBtn, Confirm},
        data() {
            return {
                loading: true,
                showCancel: false,
            }
        },
        computed: {
            items() {
                const arr1 = this.applicationList.filter((item) => {
                    return item.status !== 'Cancelled'
                })
                const arr2 = this.applicationList.filter((item) => {
                    return item.status === 'Cancelled'
                })
                return this.showCancel ? arr2 : arr1
            },
            cancelledNum() {
                return this.applicationList.filter((item) => {
                    return item.status === 'Cancelled'
                }).length
            },
            // compareDate() {
            //     return (item) => {
            //         const created = new Date(this.formatDate(item.created))
            //         const dueDate = new Date(this.formatDate(item.due_date))
            //         return dueDate.getTime() >= created.getTime()
            //     }
            // },
            // billingMap() {
            //     return (duration) => {
            //         switch (duration) {
            //             case 1:
            //                 return 'month'
            //             case 3:
            //                 return '3 months'
            //             case 6:
            //                 return '6 months'
            //             case 12:
            //                 return 'year'
            //             case 24:
            //                 return '2 years'
            //         }
            //
            //     }
            // },
            ...mapState('client', ['clientInfo']),
            ...mapState('application', ['applicationList']),
            ...mapGetters('client', ['checkInfoCompleted']),
            ...mapGetters('application', ['getProductNotext', 'getCategoryMenus', 'getPackageStatus']),
        }
        ,
        methods: {
            formatDate,
            refreshData() {
                this.getApplicationList(true)
            },
            refreshSinglePackage(uuid) {
                this.$http.get(`/packages/${uuid}/`).then(res  => {
                    const payload = this.applicationList.map(item => {
                        return item.uuid === res.uuid? res : item
                    })
                    this.setApplicationList(payload)
                }).catch(err =>{
                    // this.refreshData()
                    this.$message.error(err.detail)
                })
            },
            freeTrialHanlder() {
                this.showCancel = false
                this.refreshData()
            },
            save(formData) {
                return this.$http.put(`/packages/${formData.uuid}/`, {
                    name: formData.name
                }).then(() => {
                    //save name to store
                    const payload = this.applicationList.map(item => {
                        if (item.uuid === formData.uuid) {
                            item.name = formData.name
                        }
                        return item
                    })
                    this.setApplicationList(payload)
                    this.$message.success("Your application was renamed successfully.")
                }).catch(() => {
                    this.$message.error("Oops, application renaming failed, please contact us at support@cloudclusters.io for this issue.")
                })
            },
            cancelPackage(app) {
                return this.$http.put(`/packages/${app.uuid}/cancel/`).then(() => {
                    this.refreshData()
                    this.$message.success('The application was cancelled successfully')
                }).catch(() => {
                    this.$message.error('Failed to cancel the application. Please try again later.')
                })
            },
            deploySuccess(uuid) {
                const payload = this.applicationList.map(o => {
                    if (uuid === o.uuid) {
                        o.deploy_status = 'Deploying'
                    }
                    return o
                })
                this.setApplicationList(payload)
            },
            changeBillingCycle(formData) {
                return this.$http({
                    method: 'put',
                    url: `/packages/${formData.uuid}/billing_cycle/`,
                    data: {
                        price_id: formData.price_id,
                    }
                }).then((res) => {
                    const payload = this.applicationList.map(app => {
                        if (app.uuid === formData.uuid) {
                            app = res.package
                        }
                        return app
                    })
                    this.$message.success('The billing cycle was updated successfully!')
                    this.setApplicationList(payload)
                }).catch(() => {
                    this.$message.error('The billing cycle update failed ! Please try again later.')
                })
            },
            ...mapActions('application', ['getApplicationList']),
            ...mapMutations('application', ['setApplicationList'])
        },
        created() {
            this.colorMap = colorMap
            if (this.$route.query.show_cancelled) {
                this.showCancel = true
            }
            this.getApplicationList(true).then(() => {
                this.loading = false
            }).catch(() => {
                this.$message.error('Oops, something bad happened while list applications, please contact us at support@cloudclusters.io for this issue.')
                this.loading = false
            })

            this.$SDK.track({
                pageName: 'Applications',
            })
        }
    }
</script>

<style lang="scss">
    .applications {
        .package-wrapper {
            .grey-btn {
                background-color: rgba(0, 0, 0, .12) !important;
                color: rgba(0, 0, 0, .26) !important;
            }

            .edit-btn {
                font-size: 16px;
                cursor: pointer;
            }

            .v-icon {
                font-size: 20px;
            }

            .title {
                color: #acadb0;
            }

            .package-config {
                .img-box {
                    img {
                        height: 80px;
                        padding: 15px;
                    }
                }

                .package-title {
                    font-size: 14px;
                }

            }

            .avatar-img {
                border-radius: 5px !important;
                background-color: #f1f6f6;
                padding: 10px !important;

                div {
                    border-radius: 0px;
                }
            }

            &:hover {
                .avatar-img {
                    background-color: #fff;
                }
            }

            .package-text-wrapper {
                span {
                    line-height: 27px;
                }
            }
        }

    }
</style>
