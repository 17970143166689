<template>




    <v-layout @click.stop.prevent="''" class="layout-mask" ma-0>

        <v-flex style="flex-basis: 30%; background-color: transparent;display: flex;justify-content: center;align-items: center;">
            <v-progress-circular v-on="on" class="ml-1" size="80"  indeterminate  color="blue">
                <span v-if="progress.steps_success_num">{{progress.steps_success_num}}/{{progress.steps_total_num}}</span>
            </v-progress-circular>
        </v-flex>
        <v-flex style="flex-basis: 70%;" d-flex align-center>
            <div style="color: #0f0f0f;" >
                <p class="my-2 " v-for="(item,index) in progress.steps" :key="index">Step {{index+1}}. {{item.description}}.</p>
                <span v-if="!progress.steps">Deploying the application. It may take 3-5 minutes.</span>
            </div>
        </v-flex>
    </v-layout>
</template>



<script>
    export default {
        name: "ApplicationProcess",
        props: {
            package_uuid: String
        },
        data() {
            return {
                timer: null,
                isSuccess: false,
                progress:'',
            }
        },
        methods: {
            getApplicationStatus() {
                return this.$http.get(`/packages/${this.package_uuid}/deployment-result/`)
            },
            tick() {
                if(this.isSuccess){
                    return
                }
                this.getApplicationStatus().then(res => {
                    this.progress = res.progress
                    if (res.return_code === 2 || res.return_code === 3) {
                        this.timer = setTimeout(() => {
                            this.tick()
                        }, 5000)
                    } else {
                        this.isSuccess = true
                        this.$emit('success')
                        clearTimeout(this.timer)
                    }
                }).catch(() => {
                    this.timer = setTimeout(() => {
                        this.tick()
                    }, 5000)
                })
            }
        },
        created() {
            this.tick()
        },
        beforeDestroy() {
            this.isSuccess = true
            clearTimeout(this.timer)
        }
    }
</script>

<style lang="scss">
    .layout-mask{
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 73px;
        left: 0;
        bottom:0;
        background-color: #fff;
        opacity: .8;
        &:after{
            content: "";
            z-index: -1;
            position:absolute ;
            background: inherit;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

</style>